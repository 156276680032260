@import "../../styles/constants.scss";
@import "../../styles/mixins.module.scss";
@import "../../styles/breakpoints.module.scss";


.banner-container{
    background-color: $light-black;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $banner-height;
    padding: 0 20px 0 20px;
    z-index: 999;
}

.name {
    font-size: $large;
    font-weight: $heavy;
    color: $red;
}

.icon-list {
    display: flex;
    gap: 20px;
}

.icon-list *{
    transition: 500ms;
}

.icon-list *:hover{
    @include xs-breakpoint{
        @include hover()
    }
}