@mixin text-gradient($gradient) {
    color: transparent;
    background-image: $gradient;
    -webkit-background-clip: text;
    background-clip: text;
}

@mixin hover() {
    opacity: 0.5;
    scale: 0.95;
}