@import "../styles/breakpoints.module.scss";
@import "../styles/mixins.module.scss";
@import "../styles/constants.scss";


.App {
    display: grid;
    justify-items: center;
    grid-template-rows: calc(100vh - $banner-height) auto;
    row-gap: 50px;
}
  
.welcome{
    text-align: center;
    align-self: center;
}
  
.Hi{
    font-size: 100px;
    font-weight: 900;
    line-height: 90%;
}
  
  @media (prefers-reduced-motion: no-preference) {
    .intro{
      font-weight: 500;
      color: var(--color-white);
      font-size: 24px;
      opacity: 0%;
      animation-name: appear;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .name{
      color: var(--color-blue);
      font-size: 30px;
      font-weight: 800;
      opacity: 0%;
      animation-name: appear;
      animation-duration: 1s;
      animation-delay: 1.5s;
      animation-fill-mode: forwards;
    }
  }
  
  
  @keyframes appear {
    from {
      opacity: 0%;
    }
  
    to {
      opacity: 100%;
    }
  }
  
  .about{
    text-align: center;
    margin: 30px;
    font-size: 20px;
    font-weight: 600;
    color: var(--color-white);
    align-self: center;
    max-width: 500px;
  
    @include text-gradient($main-gradient);

  }

  .tagLine{
    color: $gray;
    font-style: italic;
    padding: 20px 0;
  }

  .projectList{
    @include sm-breakpoint{
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 50px;
        align-items: center;
    }

    @include lg-breakpoint{
      grid-template-columns: auto auto auto;
    }
  }

  .sectionTitle{
    color: $white;
    justify-self: left;
    width: 80vw;
    max-width: 650px;
    margin: auto;
    font-size: $xlarge;
    font-weight: $xheavy;
    font-style: italic;
    color: $red;
  }

  .hero{
    display: flex;
    flex-direction: column;
  }