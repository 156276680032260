.App {
  display: grid;
  justify-items: center;
  grid-template-rows: 100vh auto;
  row-gap: 50px;
}

.app-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  align-items: start;
}

.welcome {
  text-align: center;
  align-self: center;
}

.Hi {
  font-size: 200px;
  font-weight: 900;
  color: transparent;
  background-image: linear-gradient(247.24deg, #ff3864 29.59%, #623cea 101.39%);
  -webkit-background-clip: text;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .intro {
    font-weight: 500;
    color: var(--color-white);
    font-size: 24px;
    opacity: 0%;
    animation-name: appear;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .name {
    color: var(--color-blue);
    font-size: 30px;
    font-weight: 800;
    opacity: 0%;
    animation-name: appear;
    animation-duration: 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
}

@keyframes appear {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.about {
  text-align: center;
  margin: 30px;
  font-size: 20px;
  font-weight: 600;
  color: var(--color-white);
  align-self: center;
  max-width: 500px;

  color: transparent;
  background-image: linear-gradient(247.24deg, #ff3864 29.59%, #623cea 101.39%);
  -webkit-background-clip: text;
  background-clip: text;
}

@media screen and (min-width: 1020px) {
  .projectList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 50px;
    align-items: center;
  }
}
