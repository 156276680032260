.image {
    border-radius: 10px;
    display: block;
    margin: auto;
    transition: 500ms;
    max-width: 700px;
    width: 90%;
}

@media screen and (min-width: 480px) {
    .image:hover{
        scale: 0.95;
    }
}